<template>
  <footer class="bg-[#f6f2f2] text-[#121212]">
    <div
      class="container flex flex-col items-center text-center md:text-left justify-between p-6 mx-auto space-y-4 sm:space-y-0 sm:flex-row"
    >
      <div>
        <div class="text-md text-[#121212]">Striking Image</div>
        <div class="text-xs">
          &copy; Copyright {{ new Date().getFullYear() }}.<br />
          All Rights Reserved.
        </div>
      </div>

      <div
        class="flex flex-wrap items-center lg:text-center justify-center flex-1 gap-4 mt-6 lg:gap-6 lg:mt-0"
      >
        <NuxtLink
          to="/terms"
          class="text-sm transition-colors duration-300 hover:text-[#000]"
        >
          Website Terms
        </NuxtLink>
      </div>

      <div class="-mx-2">
        <div class="text-xs">Follow us on</div>
        <ClientOnly>
          <div class="flex">
            <a
              href="https://www.facebook.com/FootyFantasticFun/"
              class="mr-2 transition-colors duration-300 hover:text-[#000]"
              aria-label="Facebook"
              target="_blank"
            >
              <fa-icon
                :icon="['fab', 'facebook']"
                class="w-5 h-5 fill-current"
              />
            </a>
            <a
              href="https://twitter.com/FootyFantastic2"
              class="mx-2 transition-colors duration-300 hover:text-[#000]"
              aria-label="X"
              target="_blank"
            >
              <fa-icon
                :icon="['fab', 'x-twitter']"
                class="w-5 h-5 fill-current"
              />
            </a>
            <a
              href="https://www.etsy.com/uk/shop/StrikingImage"
              class="mx-2 transition-colors duration-300 hover:text-[#000]"
              aria-label="Etsy"
              target="_blank"
            >
              <fa-icon :icon="['fab', 'etsy']" class="w-5 h-5 fill-current" />
            </a>
            <a
              href="https://www.instagram.com/footyfantasticfun/"
              class="ml-2 transition-colors duration-300 hover:text-[#000]"
              aria-label="Instagram"
            >
              <fa-icon
                :icon="['fab', 'instagram']"
                class="w-5 h-5 fill-current"
              />
            </a>
          </div>
        </ClientOnly>
      </div>
    </div>
  </footer>
</template>
