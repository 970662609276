import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faShield, faMessage, faNoteSticky, faTag } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faXTwitter, faInstagram, faEtsy } from '@fortawesome/free-brands-svg-icons'

config.autoAddCss = false

library.add(faShield, faMessage, faNoteSticky, faTag, faFacebook, faXTwitter, faInstagram, faEtsy)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('fa-icon', FontAwesomeIcon)
})