<template>
  <nav class="relative">
    <div
      class="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center"
    >
      <div class="flex items-center justify-between">
        <NuxtLink
          to="/"
          class="flex items-center text-3xl lg:text-4xl font-pt font-bold"
        >
          Striking Image
        </NuxtLink>

        <!-- Mobile menu button -->
        <div class="flex md:hidden">
          <button
            @click="isOpen = !isOpen"
            type="button"
            class="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
            aria-label="toggle menu"
          >
            <svg
              v-if="!isOpen"
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 8h16M4 16h16"
              />
            </svg>

            <svg
              v-if="isOpen"
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <div
        :class="[
          isOpen ? 'translate-x-0 opacity-100 ' : 'opacity-0 -translate-x-full',
        ]"
        class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white md:mt-0 md:p-0 md:top-0 md:relative md:bg-transparent md:w-auto md:opacity-100 md:translate-x-0 md:flex md:items-center"
      >
        <div class="flex flex-col md:flex-row">
          <NuxtLink
            class="my-2 transition-colors duration-300 transform hover:text-[#B54103] md:mx-4 md:my-0"
            href="/#journals"
            >Journals</NuxtLink
          >
          <NuxtLink
            class="my-2 transition-colors duration-300 transform hover:text-[#B54103] md:mx-4 md:my-0"
            href="/#customise"
            >Customise</NuxtLink
          >
          <NuxtLink
            class="my-2 transition-colors duration-300 transform hover:text-[#B54103] md:mx-4 md:my-0"
            href="/#reviews"
            >Reviews</NuxtLink
          >
          <NuxtLink
            class="my-2 transition-colors duration-300 transform hover:text-[#B54103] md:mx-4 md:my-0"
            href="/#contact-us"
            >Contact</NuxtLink
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
const isOpen = ref(false)
watchEffect(() => {
  isOpen.value = false
})
</script>
