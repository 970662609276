import revive_payload_client_4sVQNw7RlN from "/Users/tomjohnson/www/personal/striking-image/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/Users/tomjohnson/www/personal/striking-image/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/tomjohnson/www/personal/striking-image/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/tomjohnson/www/personal/striking-image/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/Users/tomjohnson/www/personal/striking-image/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/Users/tomjohnson/www/personal/striking-image/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/tomjohnson/www/personal/striking-image/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import icons_sncVX745v6 from "/Users/tomjohnson/www/personal/striking-image/plugins/icons.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  icons_sncVX745v6
]