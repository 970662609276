import { default as footy_45fantastic_452CK9NOYtioAMeta } from "/Users/tomjohnson/www/personal/striking-image/pages/books/footy-fantastic-2.vue?macro=true";
import { default as footy_45fantastic_45wiro_45boundnl0VjAYFuwMeta } from "/Users/tomjohnson/www/personal/striking-image/pages/books/footy-fantastic-wiro-bound.vue?macro=true";
import { default as footy_45fantasticrhj18twztgMeta } from "/Users/tomjohnson/www/personal/striking-image/pages/books/footy-fantastic.vue?macro=true";
import { default as gymnastic_45fantastic6n78JabOl6Meta } from "/Users/tomjohnson/www/personal/striking-image/pages/books/gymnastic-fantastic.vue?macro=true";
import { default as rugby_45reviewVUa4ACM81iMeta } from "/Users/tomjohnson/www/personal/striking-image/pages/books/rugby-review.vue?macro=true";
import { default as indexrP9TKrNnpHMeta } from "/Users/tomjohnson/www/personal/striking-image/pages/index.vue?macro=true";
import { default as termsdWH0nxOX6FMeta } from "/Users/tomjohnson/www/personal/striking-image/pages/terms.vue?macro=true";
export default [
  {
    name: footy_45fantastic_452CK9NOYtioAMeta?.name ?? "books-footy-fantastic-2",
    path: footy_45fantastic_452CK9NOYtioAMeta?.path ?? "/books/footy-fantastic-2",
    meta: footy_45fantastic_452CK9NOYtioAMeta || {},
    alias: footy_45fantastic_452CK9NOYtioAMeta?.alias || [],
    redirect: footy_45fantastic_452CK9NOYtioAMeta?.redirect || undefined,
    component: () => import("/Users/tomjohnson/www/personal/striking-image/pages/books/footy-fantastic-2.vue").then(m => m.default || m)
  },
  {
    name: footy_45fantastic_45wiro_45boundnl0VjAYFuwMeta?.name ?? "books-footy-fantastic-wiro-bound",
    path: footy_45fantastic_45wiro_45boundnl0VjAYFuwMeta?.path ?? "/books/footy-fantastic-wiro-bound",
    meta: footy_45fantastic_45wiro_45boundnl0VjAYFuwMeta || {},
    alias: footy_45fantastic_45wiro_45boundnl0VjAYFuwMeta?.alias || [],
    redirect: footy_45fantastic_45wiro_45boundnl0VjAYFuwMeta?.redirect || undefined,
    component: () => import("/Users/tomjohnson/www/personal/striking-image/pages/books/footy-fantastic-wiro-bound.vue").then(m => m.default || m)
  },
  {
    name: footy_45fantasticrhj18twztgMeta?.name ?? "books-footy-fantastic",
    path: footy_45fantasticrhj18twztgMeta?.path ?? "/books/footy-fantastic",
    meta: footy_45fantasticrhj18twztgMeta || {},
    alias: footy_45fantasticrhj18twztgMeta?.alias || [],
    redirect: footy_45fantasticrhj18twztgMeta?.redirect || undefined,
    component: () => import("/Users/tomjohnson/www/personal/striking-image/pages/books/footy-fantastic.vue").then(m => m.default || m)
  },
  {
    name: gymnastic_45fantastic6n78JabOl6Meta?.name ?? "books-gymnastic-fantastic",
    path: gymnastic_45fantastic6n78JabOl6Meta?.path ?? "/books/gymnastic-fantastic",
    meta: gymnastic_45fantastic6n78JabOl6Meta || {},
    alias: gymnastic_45fantastic6n78JabOl6Meta?.alias || [],
    redirect: gymnastic_45fantastic6n78JabOl6Meta?.redirect || undefined,
    component: () => import("/Users/tomjohnson/www/personal/striking-image/pages/books/gymnastic-fantastic.vue").then(m => m.default || m)
  },
  {
    name: rugby_45reviewVUa4ACM81iMeta?.name ?? "books-rugby-review",
    path: rugby_45reviewVUa4ACM81iMeta?.path ?? "/books/rugby-review",
    meta: rugby_45reviewVUa4ACM81iMeta || {},
    alias: rugby_45reviewVUa4ACM81iMeta?.alias || [],
    redirect: rugby_45reviewVUa4ACM81iMeta?.redirect || undefined,
    component: () => import("/Users/tomjohnson/www/personal/striking-image/pages/books/rugby-review.vue").then(m => m.default || m)
  },
  {
    name: indexrP9TKrNnpHMeta?.name ?? "index",
    path: indexrP9TKrNnpHMeta?.path ?? "/",
    meta: indexrP9TKrNnpHMeta || {},
    alias: indexrP9TKrNnpHMeta?.alias || [],
    redirect: indexrP9TKrNnpHMeta?.redirect || undefined,
    component: () => import("/Users/tomjohnson/www/personal/striking-image/pages/index.vue").then(m => m.default || m)
  },
  {
    name: termsdWH0nxOX6FMeta?.name ?? "terms",
    path: termsdWH0nxOX6FMeta?.path ?? "/terms",
    meta: termsdWH0nxOX6FMeta || {},
    alias: termsdWH0nxOX6FMeta?.alias || [],
    redirect: termsdWH0nxOX6FMeta?.redirect || undefined,
    component: () => import("/Users/tomjohnson/www/personal/striking-image/pages/terms.vue").then(m => m.default || m)
  }
]