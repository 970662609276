<template>
  <div class="text-[#121212] font-verdana">
    <UiNavbar />
    <div class="">
      <main class="container mx-auto">
        <NuxtPage />
      </main>
    </div>
    <UiFooter />
  </div>
</template>
<script setup lang="ts">
useSeoMeta({
  charset: 'utf-8',
  title: 'Striking Image',
  description:
    "Explore our collection of kids' puzzle books based on football and rugby. Fun, educational, and perfect for young sports fans. Find the perfect brain-teasing adventure today!",
  viewport: 'width=device-width, initial-scale=1',
  ogTitle: 'Striking Image',
  ogType: 'website',
  ogUrl: 'www.strikingimage.co.uk',
  ogImage: 'www.strikingimage.co.uk/img/logo2.webp',
})
useHead({
  htmlAttrs: {
    lang: 'en',
  }
})
</script>
